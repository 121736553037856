import { isAfter, parse } from "date-fns";

import { DATE_FORMAT } from "./date";

export interface DateRange {
  from: string;
  to: string;
}

export const DATE_RANGE_SEPARATOR = " - ";

interface DateRangeWithParsedDate extends DateRange {
  fromDate: Date;
  toDate: Date;
}

function parseDateRangeString(value: string): DateRangeWithParsedDate | null {
  const [from, to] = value.split(DATE_RANGE_SEPARATOR);

  if (typeof from !== "string" || typeof to !== "string") {
    return null;
  }

  const fromDate = parse(from, DATE_FORMAT, new Date());
  if (fromDate.toString() === "Invalid Date") {
    return null;
  }

  const toDate = parse(to, DATE_FORMAT, new Date());
  if (toDate.toString() === "Invalid Date") {
    return null;
  }

  return { from, to, fromDate, toDate };
}

export function dateRangeFromString(value: string): DateRange | null {
  const range = parseDateRangeString(value);
  if (range === null) return null;
  const { from, to } = range;
  return { from, to };
}

export function isDateRangeStringValidFormat(value: string) {
  const range = dateRangeFromString(value);
  return range !== null;
}

export function isDateRangeStringStartLessOrEqualEnd(value: string) {
  const range = parseDateRangeString(value);
  if (range === null) return false;
  const { fromDate, toDate } = range;
  return !isAfter(fromDate, toDate);
}

export function isValidDateRange(range: DateRange) {
  const rangeString = `${range.from} - ${range.to}`;
  return (
    isDateRangeStringValidFormat(rangeString) &&
    isDateRangeStringStartLessOrEqualEnd(rangeString)
  );
}
