import { PropType } from "vue";

import { ValidationRule } from "@/lib/rules/typedefs";

export function useInputProps() {
  return {
    dense: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    hint: {
      required: false,
      type: String,
      default: undefined,
    },
    disable: {
      required: false,
      type: Boolean,
      default: false,
    },
    extraRules: {
      type: null as unknown as PropType<ValidationRule<string | null>[]>,
      required: false,
      default: [],
    },
    hideBottomSpace: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    stackLabel: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    filled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  };
}
