import { parse } from "date-fns";

export const DATE_FORMAT = "yyyy-MM-dd";

function dateFromString(value: string): string | null {
  const dateObj = parse(value, DATE_FORMAT, new Date());

  if (dateObj.toString() === "Invalid Date") {
    return null;
  }
  return value;
}

export function isDateStringValidFormat(value: string) {
  const date = dateFromString(value);
  return date !== null;
}
