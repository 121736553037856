import { isAfter, parse } from "date-fns";
import * as t from "io-ts";

import { DATETIME_FORMAT } from "./dateTime";

export const DATETIME_RANGE_SEPARATOR = " - ";

export const DateTimeRangeGuard = t.type({
  from: t.string,
  to: t.string,
});

export type DateTimeRange = t.TypeOf<typeof DateTimeRangeGuard>;

interface DateTimeRangeWithParsedDate extends DateTimeRange {
  fromDate: Date;
  toDate: Date;
}

function parseDateTimeRangeString(
  value: string
): DateTimeRangeWithParsedDate | null {
  const [from, to] = value.split(DATETIME_RANGE_SEPARATOR);

  if (typeof from !== "string" || typeof to !== "string") {
    return null;
  }

  const fromDate = parse(from, DATETIME_FORMAT, new Date());
  if (fromDate.toString() === "Invalid Date") {
    return null;
  }

  const toDate = parse(to, DATETIME_FORMAT, new Date());
  if (toDate.toString() === "Invalid Date") {
    return null;
  }

  return { from, to, fromDate, toDate };
}

export function dateTimeRangeFromString(value: string): DateTimeRange | null {
  const range = parseDateTimeRangeString(value);
  if (range === null) return null;
  const { from, to } = range;
  return { from, to };
}

export function isRangeStringValidFormat(value: string) {
  const range = dateTimeRangeFromString(value);
  return range !== null;
}

export function isRangeStringStartLessOrEqualEnd(value: string) {
  const range = parseDateTimeRangeString(value);
  if (range === null) return false;
  const { fromDate, toDate } = range;
  return !isAfter(fromDate, toDate);
}

export function isValidRange(range: DateTimeRange) {
  const rangeString = `${range.from} - ${range.to}`;
  return (
    isRangeStringValidFormat(rangeString) &&
    isRangeStringStartLessOrEqualEnd(rangeString)
  );
}
