import { format, isAfter, parse } from "date-fns";

export const DATETIME_FORMAT = "yyyy-MM-dd HH:mm";
const DATETIME_SEPARATOR = " ";

export interface DateTime {
  date: string;
  time: string;
}

export function dateObjToString(obj: Date): string {
  return format(obj, DATETIME_FORMAT);
}

export function dateObjFromString(value: string): Date | null {
  if (value.length < DATETIME_FORMAT.length) {
    return null;
  }

  const dateObj = parse(value, DATETIME_FORMAT, new Date());

  if (dateObj.toString() === "Invalid Date") {
    return null;
  }

  return dateObj;
}

function canParseDateTimeString(value: string): boolean {
  const dateObj = dateObjFromString(value);
  return dateObj !== null;
}

export function dateTimeFromString(value: string): DateTime | null {
  if (!canParseDateTimeString(value)) return null;

  const [date, time] = value.split(DATETIME_SEPARATOR);
  if (typeof date !== "string" || typeof time !== "string") {
    return null;
  }

  return {
    date,
    time,
  };
}

export function isDateTimeStringValidFormat(value: string) {
  const dateTime = dateTimeFromString(value);
  return dateTime !== null;
}

export const isEndDateAfterStartDate = (
  rawStartDate: string | null,
  rawEndDate: string | null
): true | string => {
  if (!rawEndDate) return true;
  if (!rawStartDate) return true;

  const startDateObj = dateObjFromString(rawStartDate);
  if (startDateObj === null) return true;

  const endDateObj = dateObjFromString(rawEndDate);
  if (endDateObj === null) return true;

  return (
    isAfter(endDateObj, startDateObj) ||
    "End datetime must be after start datetime"
  );
};
